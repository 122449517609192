.toolbar {
    min-height: 40px;
    height: auto !important;
    .toolbar-container {
        display: flex;

        .toolbar-search {
            display: flex;
        }
        @media screen and (max-width: 680px) {
            display: block !important;
        }
    }
}

@media screen and (max-width: 480px) {
    .toolbar {
        height: auto !important;
    
        .toolbar-container {
            display: flex;
    
            .toolbar-search {
                width: 100%;
                display: flex;
                flex-direction: column;
            }
        }
    }
}

@media screen and (max-width: 680px) {
    .toolbar {
        min-height: 100px;
        display: block !important;
    }
}

@media screen and (max-width: 990px){
    .page-title{
        margin-bottom : 0px !important;
    }

}