.select-search {
    position: relative;

    &.disabled {
        cursor: not-allowed;
        opacity: .4;
    }

    .select-list {
        overflow: hidden;
        &.show {
            transform: scale(1);
            transition: transform ease-in 200ms;
            transform-origin: top;
        }

        &.hide {
            transform: scale(1, 0);
            transition: transform ease-out 200ms;
            transform-origin: top;
        }

        &.list-group {
            position: absolute;
            top: calc(100% + .5rem);
            min-width: 100%;
            z-index: 50;
    
            .list-group-item {
    
                &.list-group-search {
                    display: flex;
                    align-items: center;
                    white-space: nowrap;
    
                    .list-search {
                        border: 1px solid #ccc;
                        padding: .75rem 1rem;
                        outline: none;
                        background-color: white;
                        border-radius: 5px;
                        flex: auto;
                    }
                }
    
                &.list-group-item-action {
                    cursor: pointer;
                }
            }
        }
    }
}