.user-form {
    .user-role {
        margin-top: 0;
    }
}

@media screen and (max-width: 480px) {
    .user-form {
        .user-info {
            margin-top: 1rem;
        }

        .user-role {
            margin-top: 1.5rem;
        }
    }
}